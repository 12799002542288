var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-dialog',{attrs:{"width":"400"},model:{value:(_vm.dialogSincronizarFeriados),callback:function ($$v) {_vm.dialogSincronizarFeriados=$$v},expression:"dialogSincronizarFeriados"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Selecione o ano desejado ")]),_c('v-card-subtitle',[_vm._v(" Informe o ano do calendário para sincronização de feriados ")]),_c('v-card-text',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"debounce":100,"name":"Ano","vid":"anoSelecionadoSincronizar","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":"Ano","items":_vm.anosSincronizar,"error-messages":errors},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":_vm.handleAtualizarFeriados}},[_vm._v("Confirmar")])]},proxy:true}],null,true),model:{value:(_vm.anoSelecionadoSincronizar),callback:function ($$v) {_vm.anoSelecionadoSincronizar=$$v},expression:"anoSelecionadoSincronizar"}})]}}],null,true)})],1)],1)]}}])})],1)],1)],1),_c('v-card',{staticClass:"ma-1 pa-3 rounded-xl mb-3"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("Feriados e férias")]),_c('v-spacer'),(_vm.allowCreate)?_c('v-btn',{attrs:{"loading":_vm.loadingAtualizaFeriados,"color":"primary"},on:{"click":_vm.handleAtualizarFeriadosDialog}},[_vm._v("Sincronizar com o calendário oficial de feriados")]):_vm._e(),(_vm.allowCreate)?_c('v-btn',{staticClass:"ml-2",attrs:{"to":"/main/EscolaFeriados/create","color":"primary"}},[_vm._v("Criar")]):_vm._e()],1),_c('v-card-subtitle',[_vm._v(" No primeiro dia do mês de Julho os feriados do próximo ano serão criados automaticamente ")]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Buscar","single-line":"","append-icon":"mdi-magnify","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-autocomplete',{attrs:{"label":"Ano","items":_vm.anos},model:{value:(_vm.anoSelecionado),callback:function ($$v) {_vm.anoSelecionado=$$v},expression:"anoSelecionado"}})],1)],1),(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_c('v-data-table',{staticClass:"ma-1",attrs:{"multi-sort":"","headers":_vm.headers,"items":_vm.feriados,"search":_vm.search,"loading-text":"Carregando...","fixed-header":"","height":"70vh","footer-props":{
      itemsPerPageOptions: [-1]
    },"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.ativo",fn:function(ref){
    var item = ref.item;
return [(item.is_active)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check")]):_vm._e()]}},{key:"item.data",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.data))+" ")]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [(_vm.allowDelete)?_c('v-icon',{on:{"click":function($event){return _vm.deleteFeriado(item)}}},[_vm._v("mdi-delete")]):_vm._e()]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }